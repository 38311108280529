const LocalesEnum = {
  RU: 'ru',
  EN: 'en',
  AZ: 'az',
  HI: 'hi',
  FR: 'fr',
  UZ: 'uz',
  PT: 'pt',
};

export const FALLBACK_LOCALE = LocalesEnum.EN;

export const localesList = Object.values(LocalesEnum);

export const LANG_LOCALSTORAGE_KEY = 'language';

export const languageNames = {
  [LocalesEnum.RU]: 'Русский',
  [LocalesEnum.EN]: 'English',
  [LocalesEnum.AZ]: 'Azərbaycan dili',
  [LocalesEnum.HI]: 'हिन्दी',
  [LocalesEnum.FR]: 'Français',
  [LocalesEnum.UZ]: 'O‘zbek tili',
  [LocalesEnum.PT]: 'Português',
};

export default LocalesEnum;
