<template>
  <div :class="$style.image" />
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { oneOf } from 'vue-types';

import { localesList } from '@/constant/LocalesEnum';

const props = defineProps({
  country: oneOf(localesList).isRequired,
});

const imageUrl = ref(null);
const backgroundImage = computed(() => (imageUrl.value ? `url("${imageUrl.value}")` : 'none'));

watch(
  () => props.country,
  async (country) => {
    const { default: image } = await import(`@/assets/images/flags/${country}.svg`);
    imageUrl.value = image;
  },
  { immediate: true },
);
</script>

<style lang="scss" module>
.image {
  width: 16px;
  height: 16px;
  background-image: v-bind(backgroundImage);
}
</style>
